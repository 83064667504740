import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SigninStatus } from '../../model/signin.status';
import { AuthService } from '../../services/auth.service';
import { aagJobPositions } from '../../model/aagJobPositions';
import { WorkgroupService } from '../../services/workgroup.service';
import { SignInProfileService } from '../../services/signinprofile.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  studentServiceErrorMsg: string;
  buttonMsg: string = 'Sign in';
  formModel: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  birthMonth: FormControl;
  birthDay: FormControl;
  birthYear: FormControl;
  controlKeyHeld = false;
  isSearching = false;
  private aagSiteContactEmail: string;

  //https://stackoverflow.com/questions/42170566/how-to-configure-ngbdropdown-to-display-the-selected-item-from-the-dropdown
  aagSiteWorkGroupPositionSelected: boolean = false;
  aagSiteWorkGroupPositions: aagJobPositions[];
  selectJobPosition: string = '';
  // aagSubscription: Subscription;

  constructor(
    formBuilder: FormBuilder,
    private _router: Router,
    private authService: AuthService,
    private signInProfileService: SignInProfileService,
    private workgroupService: WorkgroupService
  ) {
    console.debug('Event..WelcomeComponent(DEBUG) CONSTRUCTOR initialize.................' + new Date().toLocaleString());
    const maxYear = new Date().getFullYear() - 16;
    const minYear = new Date().getFullYear() - 100;
    this.formModel = formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      birthMonth: ['', [Validators.pattern('^[0-9]{2}$'), Validators.min(1), Validators.max(12)]],
      birthDay: ['', [Validators.pattern('^[0-9]{2}$'), Validators.min(1), Validators.max(31)]],
      birthYear: ['', [Validators.pattern('^[0-9]{4}$'), Validators.min(minYear), Validators.max(maxYear)]],
    });

    this.firstName = <FormControl>this.formModel.controls.firstName;
    this.lastName = <FormControl>this.formModel.controls.lastName;
    this.birthMonth = <FormControl>this.formModel.controls.birthMonth;
    this.birthDay = <FormControl>this.formModel.controls.birthDay;
    this.birthYear = <FormControl>this.formModel.controls.birthYear;
  }

  ngOnInit() {
    //this.authService.initialize(); //REVIEW!!!!!!!!!!!!!!!!!!!!!!!!!

    this.aagSiteWorkGroupPositions = this.workgroupService.getWorkgroupPositions();
    this.SetJobPositionAttributes();

    // let isReset = this.signInProfileService.isResetSessionRequested();
    // if (isReset) {
    //   this.signInProfileService.resetSessionState();
    // } else {
    //   let status = this.signInProfileService.getSSOIsAuthorizedOrNotDetected();
    //   if (status && status === 'check') {
    //     this.generateUserMessage();
    //   }
    // }

    let status = this.signInProfileService.getSSOIsAuthorizedOrNotDetected();
    if (status && status === 'check') {
      this.generateUserMessage();
    }

    // console.log('zzzzzzzzzzzzzzzzzzzzzzzzzz11: ' + new Date().toLocaleString());

    //Set initial persistence state for initial page load, redirect, or browser refresh
    //OnInit will execute whenever the page loads for the first time.  Including a page refresh
    this.signInProfileService.resetSessionState();
  }

  generateUserMessage() {
    if (this.signInProfileService.getSSOFirstName()) {
      this.studentServiceErrorMsg =
        'Hi, ' +
        this.signInProfileService.getSSOFirstName() +
        '. You may not be enrolled in any classes. Please email ' +
        this.aagSiteContactEmail +
        ' if you have any questions.';
    } else {
      this.studentServiceErrorMsg =
        'Unable to sign you in. You may not be enrolled in any classes. Please email ' +
        this.aagSiteContactEmail +
        ' if you have any questions.';
    }
  }

  ngOnDestroy(): void {
    console.debug('Event..WelcomeComponent(DEBUG) ngOnDestroy');
    // this.aagSubscription.unsubscribe();
    //this.authService.moduleDestroy();
  }

  onSubmit() {
    if (this.isSearching) {
      return;
    }

    const dateOfBirth = `${parseInt(this.birthYear.value, 10)}-${parseInt(this.birthMonth.value, 10)
      .toString()
      .padStart(2, '0')}-${parseInt(this.birthDay.value, 10).toString().padStart(2, '0')}`;

    this.studentServiceErrorMsg = null;
    this.buttonMsg = 'Signing you in. Please hang on...';
    this.isSearching = true;

    //Take operator makes a subscription happen once and exit
    let subscription1$ = this.signInProfileService.signinUserState$.pipe(take(1)).subscribe((signinProfileState) => {
      // console.debug(
      //   'WelcomeComponent(DEBUG) logIn subscribe..(' + SigninStatus[signinProfileState] + ')...........' + new Date().toLocaleString()
      // );
      this.buttonMsg = 'Sign in';
      this.isSearching = false;
      switch (signinProfileState) {
        case SigninStatus.AUTHORIZED:
          this._router.navigateByUrl('/landing');
          break;
        case SigninStatus.NOTAUTHORIZED:
          this.generateUserMessage();
          break;
        default:
          this.studentServiceErrorMsg = 'There was a problem signing you in. Please try again later.';
          break;
      }

      // this.aagSubscription.add(subscription1$);
    });

    this.signInProfileService.SharepointlogIn(this.firstName.value, this.lastName.value, dateOfBirth);
  }

  ssoLogIn() {
    //this.authService.moduleSetup();
    // this.authService.initialize();
    // console.debug('Welcome.ssoLogIn User click signon');
    this.authService.authorize();
  }

  ChangeJobPosition(newSelectedPosition: string) {
    const index = this.workgroupService.setJobPosition(newSelectedPosition);
    this.SetJobPositionAttributes();
  }

  SetJobPositionAttributes() {
    const index = this.workgroupService.getCurrentJobPositionIndex();

    //-1 indicates value is NOT found.  Set default settings
    if (index != -1) {
      this.aagSiteWorkGroupPositionSelected = true;
      this.selectJobPosition = this.workgroupService.getCurrentJobPositionTitle();
      this.aagSiteContactEmail = this.workgroupService.getCurrentBusinessSupportContact();
    } else {
      this.aagSiteWorkGroupPositionSelected = false;
      this.selectJobPosition = 'Select Job Position ..';
      this.aagSiteContactEmail = '';
    }
  }

  isSearchDisabled() {
    if (
      !this.firstName.value.trim() ||
      !this.lastName.value.trim() ||
      !this.birthDay.value.trim() ||
      !this.birthMonth.value.trim() ||
      !this.birthYear.value.trim()
    ) {
      return true;
    }
    return false;
  }

  isRequiredError(input: FormControl) {
    return (input.hasError('required') || !input.value.trim()) && input.touched;
  }

  isInvalidError(input: FormControl) {
    return this.isRequiredError(input) || (input.touched && input.invalid);
  }

  onlyNumbersKeyDown(event: any) {
    if ('Control' === event.key || 'Meta' === event.key) {
      this.controlKeyHeld = true;
    }
  }

  onlyNumbersKeyUp(event: any) {
    if ('Control' === event.key || 'Meta' === event.key) {
      this.controlKeyHeld = false;
    }
  }

  onlyNumbersKeyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    if (!pattern.test(event.key) && !(this.controlKeyHeld && 'v' === event.key)) {
      event.preventDefault();
    }
  }

  zeroPadFocusOut(formControl: FormControl, data: any) {
    // console.log(data.value);
    const val = data.value.trim();
    if (1 === val.length) {
      data.setValue('0' + val);
    }
  }

  milleniumPadFocusOut(formControl: FormControl, data: any) {
    // console.log(data.value);
    const val = data.value.trim();
    if (2 === val.length || 1 === val.length) {
      data.setValue('19' + val);
      // if (this.isNumeric(val)) {
      //   let newyear = this.calculateFullBirthYear(val);
      //   console.log('val: ' + val + ' new year: ' + newyear);
      //   data.setValue(newyear);
      // }
    }
  }

  // isNumeric = (val: string): boolean => {
  //   return !isNaN(Number(val));
  // };

  // calculateFullBirthYear(year: number): number {
  //   if (year >= 0 && year < 100) {
  //     const now = new Date();
  //     const fullYear = now.getFullYear();
  //     let shortYear = fullYear % 100;
  //     let m1 = fullYear - shortYear;
  //     let m2 = m1 - 100;

  //     let opt1 = parseInt(year.toString()) + parseInt(m1.toString());
  //     let opt2 = parseInt(year.toString()) + parseInt(m2.toString());

  //     // let opt1Year = Math.abs(fullYear - opt1);
  //     // let opt2Year = Math.abs(fullYear - opt2);

  //
  //     year = Math.abs(opt1) < fullYear - 17 ? opt1 : opt2;
  //     return year;
  //   }
  // }
}
