import { BrowserModule } from '@angular/platform-browser';
import { isDevMode, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './components/application/app.component';
import { ClassDetailsComponent } from './components/class-details/class-details.component';
import { ContentComponent } from './components/content/content.component';
import { LandingComponent } from './components/landing/landing.component';
import { FlightDetailsComponent } from './components/flight-details/flight-details.component';
import { HotelDetailsComponent } from './components/hotel-details/hotel-details.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { StudentService } from './services/student.service';
import { TileService } from './services/tile.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UxModule } from './ux/ux.module';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { AuthService } from './services/auth.service';
import { Level, NgLoggerModule } from '@nsalaun/ng-logger';
// import { CookieService } from 'ngx-cookie-service';
import { AuthLandingComponent } from './components/auth-landing/auth-landing.component';
import { AuthLoadingComponent } from './components/auth-loading/auth-loading.component';
import { filter } from 'rxjs/operators';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthConfigModule } from './auth-config.module';
import { interceptors } from './interceptors/interceptors';
import { WorkgroupService } from './services/workgroup.service';
import { SignInProfileService } from './services/signinprofile.service';
// import { aagCookieService } from './services/sessionmanager.service';

// Set different log level depending on environment.
//https://stackoverflow.com/questions/35721206/how-to-enable-production-mode
// const LOG_LEVEL = isDevMode() ? Level.DEBUG : Level.ERROR;
// if (!isDevMode()) {
//   console.log('AppModule production mode detected');
// }

const LOG_LEVEL = Level.WARN;

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    LandingComponent,
    ContentComponent,
    ClassDetailsComponent,
    FlightDetailsComponent,
    HotelDetailsComponent,
    AuthLandingComponent,
    AuthLoadingComponent,
    UnauthorizedComponent,
  ],
  imports: [
    NgbModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    UxModule,
    NgLoggerModule.forRoot(LOG_LEVEL),
    AuthConfigModule,
  ],
  providers: [
    AuthService,
    StudentService,
    TileService,
    // CookieService,
    interceptors,
    WorkgroupService,
    SignInProfileService,
    // aagCookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
      .subscribe((config) => {
        console.log('AppModule OIDC ConfigLoaded');
        // if (isDevMode()) {
        //   console.log('AppModule OIDC ConfigLoaded', config);
        // }
      });

    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.ConfigLoadingFailed))
      .subscribe(({ value }) => console.error('AppModule OIDC ConfigLoadingFailed', value));
  }
}
