export const environment = {
  name: 'prod',
  production: true,
  port: '',
  authCallbackRoute: 'auth',
  silentRenewAuthCallbackRoute: 'assets/auth.html',
  idProviderClientId: 'YjWnezyRBX8FG7JdTaAbGBt',
  idProviderUrl: 'https://fedauth.alaskasworld.com',
  appInsightsKey: '#{preclass_arm_appInsightsTelemetryValue}#',
  contentWorkgroups: {
    aocstraining: 'xv7S7oW=CP:8zp4G',
    inflight: 'CCR!TCXHsy$m3Kqw',
  },
};
