import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { version } from '../../../../package.json';
import { environment } from '../../../environments/environment';

// Now, we get a type error in line 2. TypeScript doesn't let us import a JSON module out of the box, just like that.
// This was a conscious design decision made by the TypeScript team: pulling in large JSON files could potentially
// consume a lot of memory, which is why we need to opt into that feature by enabling the --resolveJsonModule compiler flag:
// Having people to consciously opt into this would imply the user understands the cost.
// Let's head over to our tsconfig.json file and enable the resolveJsonModule option there:
//"resolveJsonModule": true

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: string;
  displayRegion: boolean = false;
  region: string = 'PreBoarding';
  version: string = version;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.initialize;

    this.displayRegion = environment.name.toLowerCase() != 'prod' ? true : false;
    this.region = this.displayRegion ? `${this.region} ${environment.name.toUpperCase()}` : this.region;
    this.title = `${this.region} V${version} at UTC ${new Date().toISOString()}`;

    // console.log(this.displayRegion);
    // console.log(this.region);
    // console.log('Title: ' + this.title);
  }
}
