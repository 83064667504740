import { Component, OnInit } from '@angular/core';
import { aagPersistentStorage } from '../../services/sessionmanager.service';
import { Router } from '@angular/router';
import { Student } from '../../model/student';
import { aagSessionStorageConstants } from '../../constants/ui-constants';
import { WorkgroupService } from '../../services/workgroup.service';

@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss'],
})
export class ClassDetailsComponent implements OnInit {
  student: Student;
  public aagSiteContactEmail: string;

  constructor(private persistenceSvc: aagPersistentStorage, private router: Router, private workgroupService: WorkgroupService) {
    // console.debug('Event..ClassDetailsComponent(DEBUG) CONSTRUCTOR initialize.................' + new Date().toLocaleString());
  }

  ngOnInit() {
    //this._activatedRoute.data.subscribe((data) => (this._routerData = data));
    this.student = this.persistenceSvc.read(aagSessionStorageConstants.SESSIONSTUDENT);
    if (!this.student) {
      this.router.navigateByUrl('/');
      return;
    }

    this.aagSiteContactEmail = this.workgroupService.getCurrentBusinessSupportContact();
  }

  signOut() {
    this.persistenceSvc.write(aagSessionStorageConstants.SESSSIONRESET, 'NOW');
    this.router.navigateByUrl('/');
  }
}
