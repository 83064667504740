import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Student, StudentSignInResponse } from '../model/student';
import { ServiceStatus } from '../model/service.status';
import { catchError, map, timeoutWith } from 'rxjs/operators';
// import { timeout } from 'q';

@Injectable()
export class StudentService {
  private _students: Array<Student>;
  private _signedInStudent: Student;

  constructor(private _http: HttpClient) {}

  getSignedInStudent(): Student {
    if (this._signedInStudent) {
      return this._signedInStudent;
    }
    return null;
  }

  getStudents(): Student[] {
    if (this._students) {
      return this._students;
    }
    return null;
  }

  signIn(firstName: string, lastName: string, dateOfBirth: string, workgroup: string): Observable<StudentSignInResponse> {
    const options = {
      params: new HttpParams()
        .set('firstName', firstName)
        .set('lastName', lastName)
        .set('dateOfBirth', dateOfBirth)
        .set('querySiteId', workgroup),
    };

    let url = '/api/1/signin';
    return this._http.get<Student>(url, options).pipe(
      timeoutWith(
        15000,
        defer(() => throwError(new HttpErrorResponse({})))
      ),
      map((response) => {
        this._signedInStudent = response;

        return {
          status: ServiceStatus.SUCCESS,
          student: response,
        };
      }),
      catchError((err: HttpErrorResponse) => {
        this.errorHandler(err, url, 'GET stdSignin');

        // if (timeout === err.statusText) {
        //   return of({
        //     status: ServiceStatus.TIMEOUT,
        //     student: null
        //   });
        // }
        if (404 === err.status) {
          return of({
            status: ServiceStatus.DOES_NOT_EXIST,
            student: null,
          });
        }

        console.log(err);
        //return throwError(err);
        return of({
          status: ServiceStatus.SYSTEM_FAILURE,
          student: null,
        });
      })
    );
  }

  ssoSignIn(token: string, workgroup: string): Observable<StudentSignInResponse> {
    const options = {
      params: new HttpParams().set('ssoToken', token).set('querySiteId', workgroup),
    };

    let url = '/api/1/signin';
    return this._http.get<Student>(url, options).pipe(
      timeoutWith(
        15000,
        defer(() => throwError(new HttpErrorResponse({})))
      ),
      map((response) => {
        // console.log('signin passed.............');

        if (!response.trainingClasses) {
          this._signedInStudent = response;

          return {
            status: ServiceStatus.DOES_NOT_EXIST,
            student: response,
          };
        } else {
          this._signedInStudent = response;

          return {
            status: ServiceStatus.SUCCESS,
            student: response,
          };
        }
      }),
      catchError((err: HttpErrorResponse) => {
        // if (timeout === err.statusText) {
        //   return of({
        //     status: ServiceStatus.TIMEOUT,
        //     student: null
        //   });
        // }

        this.errorHandler(err, url, 'GET ssoLogin');

        // if (404 === err.status) {
        //   debugger;
        //   return of({
        //     status: ServiceStatus.DOES_NOT_EXIST,
        //     student: null,
        //   });
        // }

        console.log(err);
        //return throwError(err);
        return of({
          status: ServiceStatus.SYSTEM_FAILURE,
          student: null,
        });
      })
    );
  }

  /*
   * This is a common error handler to log in app insights
   * operation example format: GET Valid Bases
   * body: send it for POST, PUT, DELETE Operations
   */
  private errorHandler<T>(err: any, url?: string, operation = 'operation', reqBody?: any) {
    let errorMessage = JSON.stringify(err.error);
    let loggableErr = {} as Error;
    loggableErr.stack = errorMessage;
    //trap body as json string to add to trace in app insights
    let traceMessage: any = `${operation}-${url}`;
    if (reqBody != null) {
      traceMessage = `${operation}-${url}-${JSON.stringify(reqBody)}`;
    }

    //log trace in app insights
    //this.logService.logTrace(traceMessage, { url: url });
    console.log(traceMessage);
    //log error in app insights
    //this.logService.logException(loggableErr, 1);
    console.log(loggableErr);

    return throwError(errorMessage);
  }

  // private traceHandler(url: string, data: any) {
  //   if (environment.logTarget != 'None') this.logService.logTrace('Url: ' + url, [{ RespData: JSON.stringify(data) }]);
  // }
}
