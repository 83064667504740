import { Component, OnInit } from '@angular/core';
import { aagPersistentStorage } from '../../services/sessionmanager.service';
import { Router } from '@angular/router';
import { Student } from '../../model/student';
import { TrainingClass } from '../../model/trainingclass';
import { AuthService } from '../../services/auth.service';
import { aagApplicationConstants, aagSessionStorageConstants } from '../../constants/ui-constants';
import { WorkgroupService } from '../../services/workgroup.service';
import { Tile } from '../../model/tile';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public externalLinks: Array<Tile> = new Array<Tile>();
  student: Student;

  constructor(private _persistenceSvc: aagPersistentStorage, private _router: Router, private workgroupService: WorkgroupService) {
    // console.debug('Event..LandingComponent(DEBUG) CONSTRUCTOR initialize.................' + new Date().toLocaleString());
  }

  ngOnInit() {
    this.student = this._persistenceSvc.read(aagSessionStorageConstants.SESSIONSTUDENT);
    if (!this.student) {
      this._router.navigateByUrl('/');
      return;
    }

    //Depending upon the workgroup flights can be booked on ID90 or FLY
    if (this.workgroupService.getCurrentJobPositionTitle() === aagApplicationConstants.WORKGROUP_INFLIGHT) {
      this.externalLinks.push(
        this.buildExternalTile('Pre-arrival course 1', 'https://initialweek0aif.s3.us-west-2.amazonaws.com/index.html')
      );
      this.externalLinks.push(
        this.buildExternalTile('Pre-arrival course 2', 'https://initialweek0bak.s3.us-west-2.amazonaws.com/index.html')
      );
      this.externalLinks.push(
        this.buildExternalTile('Pre-arrival course 3', 'https://initialweek0cfr.s3.us-west-2.amazonaws.com/index.html')
      );
    } else {
      this.externalLinks.push(this.buildExternalTile('Getting started', 'https://www.alaskaair.com/training'));
    }
  }

  buildExternalTile(tileTitle, tilehtmlref): Tile {
    var currentTimeInMilliseconds = Date.now(); // unix timestamp in milliseconds
    let tileItem: Tile = {
      id: currentTimeInMilliseconds.toString(),
      title: tileTitle,
      displayHtml: tilehtmlref,
    };

    return tileItem;
  }

  daysUntilClass(trainingClass: TrainingClass) {
    var today = new Date();
    var classDate = new Date(trainingClass.startDate);
    return Math.floor((today.valueOf() - classDate.valueOf()) / 86400000) * -1;
  }

  signOut() {
    this._persistenceSvc.write(aagSessionStorageConstants.SESSSIONRESET, 'NOW');
    this._router.navigateByUrl('/');
  }
}
