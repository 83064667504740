import { Injectable } from '@angular/core';
import { Logger } from '@nsalaun/ng-logger';
import { Subject } from 'rxjs';
import { aagSessionStorageConstants } from '../constants/ui-constants';
import { StudentSignInResponse } from '../model/student';
import { AuthService } from './auth.service';
import { ServiceStatus } from '../model/service.status';
import { SigninStatus } from '../model/signin.status';
import { aagPersistentStorage } from './sessionmanager.service';
import { StudentService } from './student.service';
import { WorkgroupService } from './workgroup.service';

@Injectable({
  providedIn: 'root',
})
export class SignInProfileService {
  private firstName: string = '';

  /**
   * The backing state used by the state$ observable, this keeps track of current item in the application. When a
   * value is set or removed, .next() needs to be called on state$ to propagate that change to all subscribers
   */

  private signinUserState: SigninStatus = SigninStatus.WAIT;

  /**
   * An Subject observable state will published values that were emitted after the subscription.   No past history is provided
   */
  public signinUserState$ = new Subject<SigninStatus>();

  constructor(
    private authService: AuthService,
    private persistenceSvc: aagPersistentStorage,
    private studentService: StudentService,
    private logger: Logger,
    private workgroupService: WorkgroupService
  ) {
    logger.debug('signinService(DEBUG) CONSTRUCTOR initialize.................' + new Date().toLocaleString());
  }

  private processSigninProfile(signInResponse: StudentSignInResponse) {
    switch (signInResponse.status) {
      case ServiceStatus.SUCCESS:
        this.persistenceSvc.write(aagSessionStorageConstants.SESSIONSTUDENT, signInResponse.student);
        this.signinUserState = SigninStatus.AUTHORIZED;
        break;
      case ServiceStatus.DOES_NOT_EXIST:
        if (!this.firstName) this.firstName = signInResponse.student.firstName;
        // console.log(signInResponse.student);
        // this.persistenceSvc.write(aagSessionStorageConstants.SSOISAUTHORIZED, 'false');
        this.persistenceSvc.write(aagSessionStorageConstants.SSOFIRSTNAME, this.firstName);
        this.signinUserState = SigninStatus.NOTAUTHORIZED;
        break;
      default:
        this.signinUserState = SigninStatus.SYSTEM_FAILURE;
        this.resetSessionState();
    }

    this.signinUserState$.next(this.signinUserState);
    // this.logger.debug('signinServiceZ(DEBUG) this.signinUserState: ' + SigninStatus[this.signinUserState]);
  }

  SSOlogIn(token: string) {
    this.firstName = '';
    let positionId: string = this.workgroupService.getCurrentJobPositionId();

    // this.logger.debug('signinServiceZ(DEBUG) SSOlogIn start....');
    this.studentService.ssoSignIn(token, positionId).subscribe((signInResponse) => {
      // this.logger.debug('signinServiceZ(DEBUG) SSOlogIn subscribe.................' + new Date().toLocaleString());

      this.processSigninProfile(signInResponse);
    });
  }

  SharepointlogIn(firstName, lastName, dateOfBirth) {
    this.firstName = firstName;
    let positionId: string = this.workgroupService.getCurrentJobPositionId();

    // this.logger.debug('signinServiceZ(DEBUG) SharepointlogIn start....');
    this.studentService.signIn(firstName, lastName, dateOfBirth, positionId).subscribe((signInResponse) => {
      // this.logger.debug('signinServiceZ(DEBUG) SharepointlogIn subscribe.................' + new Date().toLocaleString());
      this.processSigninProfile(signInResponse);
    });
  }

  isResetSessionRequested(): boolean {
    let isReset: boolean = false;
    let detectedReset = this.persistenceSvc.read(aagSessionStorageConstants.SESSSIONRESET);
    if (detectedReset) {
      // console.log('SignInProfileService......................reset requested');
      isReset = true;
    }
    return isReset;
  }

  resetSessionState() {
    let preserveSessionKeys = new Map<string, any>();
    let sessionKeys = new Map<string, any>();

    sessionKeys = this.persistenceSvc.readall();
    for (let [key, value] of sessionKeys) {
      if (key.startsWith('PERSIST')) {
        // console.log('SignInProfileService PRESERVE READ DATA.... key: ' + key + ' = ' + value);
        preserveSessionKeys.set(key, value);
      }
    }

    //Clear data
    this.authService.logoff();
    this.persistenceSvc.clear();

    //Set initial state items
    //this.persistenceSvc.write(aagSessionStorageConstants.SSOISAUTHORIZED, 'false');
    for (let [key, value] of preserveSessionKeys) {
      // console.log('SignInProfileService PRESERVE SET DATA.... key: ' + key + ' = ' + value);
      this.persistenceSvc.write(key, value);
    }
    this.signinUserState = SigninStatus.WAIT;
  }

  // setAuthorization(auth: boolean): SigninStatus {
  //   // if (auth) {
  //   //   this.persistenceSvc.write(aagSessionStorageConstants.SSOISAUTHORIZED, 'true');
  //   //   return SigninStatus.AUTHORIZED;
  //   // } else {
  //   //   this.persistenceSvc.write(aagSessionStorageConstants.SSOISAUTHORIZED, 'false');
  //   //   return SigninStatus.NOTAUTHORIZED;
  //   // }

  //   this.signinUserState = auth ? SigninStatus.AUTHORIZED : SigninStatus.NOTAUTHORIZED;
  //   return this.signinUserState;
  // }

  //Overloading TypeScript Functions
  setAuthorization(auth: boolean): SigninStatus;
  setAuthorization(auth: SigninStatus);
  setAuthorization(auth: boolean | SigninStatus): SigninStatus {
    // console.log('type detected: ' + typeof auth);
    if (auth && typeof auth === 'boolean') {
      this.signinUserState = auth ? SigninStatus.AUTHORIZED : SigninStatus.NOTAUTHORIZED;
    } else {
      this.signinUserState = auth as SigninStatus;
    }

    return this.signinUserState;
  }

  getSSOStudentProfile() {
    let data = this.persistenceSvc.read(aagSessionStorageConstants.SESSIONSTUDENT);
    return data;
  }

  getSSOIsAuthorizedOrNotDetected(): string {
    let data: string = '';
    let isAuthorizedCheck = this.getSSOIsAuthorized();

    if (isAuthorizedCheck != null && !isAuthorizedCheck) {
      data = 'check';
    }

    return data;
  }

  getSSOIsAuthorized(): boolean | null {
    let check: boolean = null;

    // let isAuthorizedCheck = this.persistenceSvc.read(aagSessionStorageConstants.SSOISAUTHORIZED);
    // if (isAuthorizedCheck) {
    //   data = isAuthorizedCheck === 'true';
    // }

    switch (this.signinUserState) {
      case SigninStatus.NOTAUTHORIZED:
        check = false;
        break;
      case SigninStatus.AUTHORIZED:
        check = true;
      default:
        break;
    }

    return check;
  }

  getSSOFirstName() {
    let detectedFirstName = this.persistenceSvc.read(aagSessionStorageConstants.SSOFIRSTNAME);

    let actualFirstName = detectedFirstName ? detectedFirstName : '';
    return actualFirstName;
  }
}

// if( value ) {
// }
// will evaluate to true if value is not:

// null
// undefined
// NaN
// empty string ''
// 0
// false
// typescript includes javascript rules.
