<div>
  <div class="row" id="title" class="mb-3" style="text-align: center">
    <h2>Sign in to your training portal</h2>
  </div>
  <div id="signInPageError" class="mb-3" *ngIf="studentServiceErrorMsg">
    {{ studentServiceErrorMsg }}
  </div>

  <div class="row">
    <div class="col"><h3 style="text-align: right">Job Position</h3></div>
    <div class="col">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="jobPositionMenu" ngbDropdownToggle>{{ selectJobPosition }}</button>
        <div ngbDropdownMenu aria-labelledby="jobPositionMenu">
          <button
            ngbDropdownItem
            *ngFor="let sortJobPosition of aagSiteWorkGroupPositions"
            (click)="ChangeJobPosition(sortJobPosition.jobPosition)"
          >
            {{ sortJobPosition.jobPosition }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="aagSiteWorkGroupPositionSelected">
    <form class="col" id="newUser" [formGroup]="formModel" (ngSubmit)="onSubmit()" novalidate>
      <h3>If this is your first class with us</h3>
      <div>
        <app-input [label]="'First name'" [labelClass]="'name'" [isInvalid]="isRequiredError(firstName)">
          <input type="text" id="firstName" placeholder=" " maxlength="75" autocomplete="off" formControlName="firstName" />
        </app-input>
      </div>
      <div class="mb-2">
        <app-input [label]="'Last name'" [labelClass]="'name'" [isInvalid]="isRequiredError(lastName)">
          <input type="text" id="lastName" placeholder=" " maxlength="75" autocomplete="off" formControlName="lastName" />
        </app-input>
      </div>
      <div>Date of birth</div>
      <div class="mb-3">
        <app-input [label]="'Month'" [labelClass]="'birthDayAndMonth'" [isInvalid]="isInvalidError(birthMonth)">
          <input
            type="text"
            id="birthMonth"
            placeholder=" "
            maxlength="2"
            autocomplete="off"
            formControlName="birthMonth"
            (keydown)="onlyNumbersKeyDown($event)"
            (keyup)="onlyNumbersKeyUp($event)"
            (keypress)="onlyNumbersKeyPress($event)"
            (focusout)="zeroPadFocusOut($event, birthMonth)"
          />
        </app-input>
        <app-input [label]="'Day'" [labelClass]="'birthDayAndMonth'" [isInvalid]="isInvalidError(birthDay)">
          <input
            type="text"
            id="birthDay"
            placeholder=" "
            maxlength="2"
            autocomplete="off"
            formControlName="birthDay"
            (keydown)="onlyNumbersKeyDown($event)"
            (keyup)="onlyNumbersKeyUp($event)"
            (keypress)="onlyNumbersKeyPress($event)"
            (focusout)="zeroPadFocusOut($event, birthDay)"
          />
        </app-input>
        <app-input [label]="'Year'" [labelClass]="'birthYear'" [isInvalid]="isInvalidError(birthYear)">
          <input
            type="text"
            id="birthYear"
            placeholder=" "
            maxlength="4"
            autocomplete="off"
            formControlName="birthYear"
            (keydown)="onlyNumbersKeyDown($event)"
            (keyup)="onlyNumbersKeyUp($event)"
            (keypress)="onlyNumbersKeyPress($event)"
            (focusout)="milleniumPadFocusOut($event, birthYear)"
          />
        </app-input>
      </div>
      <div>
        <p>
          <button id="searchButton" class="btn btn-primary btn-block" type="submit" [disabled]="isSearchDisabled()">
            {{ buttonMsg }}
          </button>
        </p>
      </div>
    </form>
    <div class="col" id="sso" style="padding-top: 10px">
      <h3>If you're back for another class</h3>
      <button id="ssoButton" class="btn btn-primary btn-block" (click)="ssoLogIn()">Sign in with Single Sign On</button>
    </div>
  </div>
  <div class="row" id="wordmark">
    <img class="mx-auto" src="/assets/wordmark.png" alt="Alaska Airlines logo" width="150" height="75" />
  </div>
</div>
