import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { aagApplicationConstants, aagSessionStorageConstants } from '../constants/ui-constants';
import { aagJobPositions } from '../model/aagJobPositions';
import { aagPersistentStorage } from './sessionmanager.service';

@Injectable({
  providedIn: 'root',
})
export class WorkgroupService {
  /**
   * Current workgroups job positions available
   */
  private availableWorkPositions: aagJobPositions[] = [
    {
      jobPosition: aagApplicationConstants.WORKGROUP_CSA,
      jobPositionId: environment.contentWorkgroups.aocstraining,
      jobEmailContact: aagApplicationConstants.WORKGROUP_EMAIL_CSA,
    },
    {
      jobPosition: aagApplicationConstants.WORKGROUP_INFLIGHT,
      jobPositionId: environment.contentWorkgroups.inflight,
      jobEmailContact: aagApplicationConstants.WORKGROUP_EMAIL_INFLIGHT,
    },
  ];

  private currentWorkId: number = -1;

  constructor(private _persistenceSvc: aagPersistentStorage) {
    //Determine if session item has been set and restore current entry
    let detected = this._persistenceSvc.read(aagSessionStorageConstants.SESSIONJOBPOSITIONID);
    if (detected) {
      this.currentWorkId = this.getIndexByPositionId(detected);
    }
  }

  private getIndexByPositionId(item: string): number {
    //Search for “find index of object in array” class
    //-1 indicates value is NOT found.  This should not happen

    const index = this.availableWorkPositions.map((e) => e.jobPositionId).indexOf(item);
    return index;
  }

  private getIndexByPositionName(item: string): number {
    //Search for “find index of object in array” class
    //-1 indicates value is NOT found.  This should not happen

    const index = this.availableWorkPositions.map((e) => e.jobPosition).indexOf(item);
    return index;
  }

  public setJobPosition(newSelectedPosition: string) {
    this.currentWorkId = this.getIndexByPositionName(newSelectedPosition);

    if (this.currentWorkId != -1) {
      let currentWorkPositionId = this.availableWorkPositions[this.currentWorkId].jobPositionId;
      this._persistenceSvc.write(aagSessionStorageConstants.SESSIONJOBPOSITIONID, currentWorkPositionId);

      // console.log('workgroup.setJobPosition ' + newSelectedPosition + ' ==> ' + currentWorkPositionId);
    }
  }

  public removeJobPosition(newSelectedPosition: string) {
    this.currentWorkId = this.getIndexByPositionName(newSelectedPosition);

    //-1 indicates value is NOT found.  This should not happen
    if (this.currentWorkId != -1) {
      this._persistenceSvc.remove(aagSessionStorageConstants.SESSIONJOBPOSITIONID);
    }
  }

  public getCurrentJobPositionId(): string {
    let data = '';
    if (this.currentWorkId > -1) data = this.availableWorkPositions[this.currentWorkId].jobPositionId;

    return data;
  }

  public getCurrentJobPositionTitle(): string {
    let data = '';
    if (this.currentWorkId > -1) data = this.availableWorkPositions[this.currentWorkId].jobPosition;

    return data;
  }

  public getCurrentBusinessSupportContact(): string {
    let data = '';
    if (this.currentWorkId > -1) data = this.availableWorkPositions[this.currentWorkId].jobEmailContact;

    return data;
  }

  public getCurrentJobPositionIndex(): number {
    return this.currentWorkId;
  }

  public getWorkgroupPositions(): aagJobPositions[] {
    return this.availableWorkPositions;
  }
}
