import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
// import { environment } from '../../../environments/environment';
import { Logger } from '@nsalaun/ng-logger';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, take, timeout } from 'rxjs/operators';
import { SignInProfileService } from '../../services/signinprofile.service';
// import { SigninStatus } from '../../services/signin.status';

// const authCallbackRoute = `/${environment.authCallbackRoute}`;

@Component({
  selector: 'app-auth-landing',
  templateUrl: './auth-landing.component.html',
  styleUrls: ['./auth-landing.component.scss'],
})
export class AuthLandingComponent implements OnInit, OnDestroy {
  // aagSubscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private signInProfileService: SignInProfileService,
    private logger: Logger,
    private router: Router
  ) {
    this.logger.debug('AuthlandingComponent..CONSTRUCTOR initialize.................' + new Date().toLocaleString());

    authService.initialize();
  }

  ngOnInit() {
    this.logger.debug('AuthlandingComponent..INIT initialize.................' + new Date().toLocaleString());

    let subscription$1 = this.authService.isAuthenticated$
      .pipe(
        timeout(8000),
        catchError((error) => of(false))
      )
      .pipe(take(1))
      .subscribe((auth) => {
        this.logger.debug('AuthlandingComponent...isAuthenticated .................. ngOnInit auth:' + auth);

        this.signInProfileService.setAuthorization(auth);
        this.router.navigateByUrl('/authLoading');

        // let status = this.signInProfileService.setAuthorization(auth);

        // if (status === SigninStatus.AUTHORIZED || status === SigninStatus.NOTAUTHORIZED) {
        //   this.router.navigateByUrl('/authLoading');
        // }
      });

    // this.aagSubscription.add(subscription$1);

    // if (window.location.href.indexOf('error=access_denied') > 0) {
    //   this.logger.warn('AuthlandingComponent..INIT access denied detected.................' + new Date().toLocaleString());
    //   //this.router.navigateByUrl('/unauthorized');
    // }

    // if (authCallbackRoute === window.location.pathname) {
    //   this.logger.debug('AuthLandingComponent Callback Route(' + authCallbackRoute + ') detected... Waiting');
    // } else {
    //   this.logger.warn('AuthLandingComponent Callback Route(' + authCallbackRoute + ') NOT detected');
    // }
  }

  ngOnDestroy(): void {
    this.logger.debug('AuthLandingComponent Destroy');
    // this.aagSubscription.unsubscribe();
    // this.authService.moduleDestroy();
  }
}

// // RxJS v6+
// import { interval, timer } from 'rxjs';
// import { takeUntil } from 'rxjs/operators';

// //emit value every 1s
// const source = interval(1000);
// //after 5 seconds, emit value
// const timer$ = timer(5000);
// //when timer emits after 5s, complete source
// const example = source.pipe(takeUntil(timer$));
// //output: 0,1,2,3
// const subscribe = example.subscribe(val => console.log(val));
