import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule, LogLevel, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

export function configureAuth(oidcConfigService: OidcConfigService) {
  const sitePort = environment.port ? `:${environment.port}` : '';
  const appUrlPrefix = `${window.location.protocol}//${window.location.hostname}${sitePort}`;
  const authUrl = `${appUrlPrefix}/${environment.authCallbackRoute}`;

  //Ping federate configuration controls types of response types allowed
  const authorizationConstants = 'id_token token'; //Configuration for Onboarding   IMPLICIT Tokens - THIS IS INSECURE AND SHOULD BE SWITCHED TO Explicit Token(code)
  //const authorizationConstants = 'code'; //Configuration for https://cbmgrapproval-test.azurewebsites.net/

  // console.log('AuthConfigModule appUrlPrefix: ' + appUrlPrefix);
  // console.log('AuthConfigModule authUrl: ' + authUrl);
  // console.log('AuthConfigModule authorizationConstants: ' + authorizationConstants);
  // console.log('AuthConfigModule port - ConfigPort: ' + environment.port + ' SitePort: ' + sitePort);
  // console.log('IsProduction level: ' + environment.production);

  return () =>
    oidcConfigService.withConfig({
      stsServer: environment.idProviderUrl,
      redirectUrl: authUrl, //Allowed values are limited by application ping federate configuration
      clientId: environment.idProviderClientId,
      responseType: authorizationConstants, //'code', //'id_token token'  // environment.authorizationConstants.responseType,  //Allowed values are limited by application ping federate configuration
      scope: 'openid email profile', //Output information
      postLogoutRedirectUri: appUrlPrefix + '/',
      postLoginRoute: '/authLoading',
      forbiddenRoute: '/unauthorized',
      unauthorizedRoute: '/unauthorized',
      //auto_userinfo = false,
      logLevel: environment.production ? LogLevel.Warn : LogLevel.Debug,
      maxIdTokenIatOffsetAllowedInSeconds: 300, //20
      silentRenew: false,
      //authWellknownEndpoint: 'bingo',   //Generate an error for debugging failures with oidc.  Turn off for production
      renewTimeBeforeTokenExpiresInSeconds: 45, //silent_renew_offset_in_seconds : -45,
      //eagerLoadAuthWellKnownEndpoints	//boolean	Tells if the AuthWellKnownEndpoints should be loaded on start or when the user calls the authorize method
    });
}

@NgModule({
  imports: [AuthModule.forRoot()],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}

// console.log('Initial configureAuth issuer: ' + environment.authorizationConstants.issuer);
// console.log('Initial configureAuth redirectUri: ' + environment.authorizationConstants.redirectUri);
// console.log('Initial configureAuth clientId: ' + environment.authorizationConstants.clientId);
// return () =>
// //Review pkccode
//   oidcConfigService.withConfig({
//     stsServer: environment.idProviderUrl,
//     redirectUrl: authUrl, //environment.authorizationConstants.redirectUri,
//     //postLogoutRedirectUri: `${this.appUrlPrefix}/`, //environment.authorizationConstants.redirectUri,
//     clientId: environment.idProviderClientId,
//     scope: 'openid email profile', //environment.authorizationConstants.scope,
//     responseType: authorizationConstants, //'code', //'id_token token'  // environment.authorizationConstants.responseType,
//     silentRenew: false, //environment.authorizationConstants.silent_renew,
//     // silentRenewUrl: `${window.location.origin}/silent-renew.html`,
//     logLevel: LogLevel.Debug,
//     maxIdTokenIatOffsetAllowedInSeconds: 300 //20 //environment.authorizationConstants.max_id_token_iat_offset_allowed_in_seconds,
//   });

// private configureOpenId() {
//   const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
//   openIDImplicitFlowConfiguration.stsServer = environment.idProviderUrl;
//   openIDImplicitFlowConfiguration.redirect_url = this.authUrl;
//   openIDImplicitFlowConfiguration.client_id = environment.idProviderClientId;
//   openIDImplicitFlowConfiguration.response_type = 'id_token token';
//   openIDImplicitFlowConfiguration.scope = 'openid email profile';
//   openIDImplicitFlowConfiguration.post_logout_redirect_uri = `${this.appUrlPrefix}/`;
//   openIDImplicitFlowConfiguration.post_login_route = '/authLoading';
//   openIDImplicitFlowConfiguration.forbidden_route = '/';
//   openIDImplicitFlowConfiguration.unauthorized_route = '/';
//   openIDImplicitFlowConfiguration.auto_userinfo = false;
//   openIDImplicitFlowConfiguration.log_console_warning_active = true;
//   openIDImplicitFlowConfiguration.log_console_debug_active = !environment.production;
//   openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 20;
//   openIDImplicitFlowConfiguration.silent_renew = false;
//   openIDImplicitFlowConfiguration.silent_renew_offset_in_seconds = -45;
//   openIDImplicitFlowConfiguration.override_well_known_configuration = true;
//   openIDImplicitFlowConfiguration.override_well_known_configuration_url =
//     `${this.appUrlPrefix}/.well-known/openid-configuration`;

//   this.oidcSecurityService.setupModule(openIDImplicitFlowConfiguration);
// }

// import { APP_INITIALIZER, NgModule } from '@angular/core';
// import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
// import { environment } from '../environments/environment';

// export function configureAuth(oidcConfigService: OidcConfigService) {
//   return () =>
//     oidcConfigService.withConfig({
//       stsServer: 'https://fedauth.qa.alaskasworld.com',
//       redirectUrl: window.location.origin,
//       postLogoutRedirectUri: window.location.origin,
//       clientId: 'fT18ixf45IqOubyMu',
//       scope: 'openid profile email',
//       responseType: 'code',
//       silentRenew: true,
//       silentRenewUrl: `${window.location.origin}/silent-renew.html`,
//       renewTimeBeforeTokenExpiresInSeconds: 10,
//       logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
//     });
// }

// @NgModule({
//   imports: [AuthModule.forRoot()],
//   providers: [
//     OidcConfigService,
//     {
//       provide: APP_INITIALIZER,
//       useFactory: configureAuth,
//       deps: [OidcConfigService],
//       multi: true,
//     },
//   ],
//   exports: [AuthModule],
// })
// export class AuthConfigModule {}
