//CLoned from Deice app: https://dev.azure.com/itsals/AirportOperations/_git/AO.DeiceClient
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Converts all requests to authentication provider to local requests
   * so that they are proxied to avoid CORS issues when deployed
   *
   * @param req the request being made by RAIN
   * @param next the next request handler in the chain
   * @returns the HTTP event
   */
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Make all OAuth requests local to enable reverse proxy to avoid CORs issue with PingFederate when deployed
    if (req.url.includes(environment.idProviderUrl)) {
      const originalURL = req.url;
      const requestToForward = req.clone({
        url: originalURL.replace(environment.idProviderUrl, window.location.origin),
      });
      return next.handle(requestToForward);
    } else {
      return next.handle(req);
    }
  }
}
