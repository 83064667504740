import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class aagPersistentStorage implements AbstractSecurityStorage {
  read(key: string) {
    let item = sessionStorage.getItem(key);
    if (!!item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }

  write(key: string, value: any): void {
    value = value || null;
    const jsonValue = JSON.stringify(value);
    sessionStorage.setItem(key, jsonValue);
  }

  remove(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(): void {
    sessionStorage.clear();
  }

  readall(): Map<string, any> {
    let saveKeys = new Map<string, any>();
    for (let i = 0; i < sessionStorage.length; i++) {
      // console.log(this.persistenceSvc.key(i) + '=[' + this.persistenceSvc.read(this.persistenceSvc.key(i)) + ']');

      saveKeys.set(sessionStorage.key(i), this.read(sessionStorage.key(i)));
    }

    return saveKeys;
  }

  check(key: string) {
    let result = this.read(key);
    let isDetected = result;
    return isDetected;
  }
}

@Injectable({
  providedIn: 'root',
})
export class aagLocalStorageMock implements AbstractSecurityStorage {
  store = {};
  constructor() {}

  clear() {
    this.store = {};
  }

  read(key) {
    let jsonObject = this.store[key] || null;
    // console.log(typeof jsonObject);
    // console.log(jsonObject);
    if (!!jsonObject) {
      let result = jsonObject;
      // let result = JSON.parse(jsonObject);
      // console.log(result);
      // console.log(typeof result);
      // console.log(result.id);
      return result;
    } else {
      return null;
    }
  }

  write(key, value) {
    value = value || null;
    // const jsonValue = JSON.stringify(value);
    this.store[key] = value;
  }

  remove(key) {
    delete this.store[key];
  }

  readall(): Map<string, any> {
    let saveKeys = new Map<string, any>();

    // iterate over an object
    for (const key in this.store) {
      console.log(`${key}: ${this.store[key]}`);
      saveKeys.set(key, this.store[key]);
    }

    return saveKeys;
  }

  check(key: string) {
    let result = this.read(key);
    let isDetected = result;
    return isDetected;
  }
}
