import { Component, OnInit } from '@angular/core';
import { aagPersistentStorage } from '../../services/sessionmanager.service';
import { TileService } from '../../services/tile.service';
import { Tile } from '../../model/tile';
import { ServiceStatus } from '../../model/service.status';
import { Router, ActivatedRoute } from '@angular/router';
import { Student } from '../../model/student';
import { aagSessionStorageConstants } from '../../constants/ui-constants';
import { WorkgroupService } from '../../services/workgroup.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {
  private _routerData: any;
  tiles: Array<Tile>;
  student: Student;
  showTravel: boolean;
  tileServiceErrorMsg: String;
  public userContent: String = '';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _persistenceSvc: aagPersistentStorage,
    private _router: Router,
    private _tileService: TileService,
    private workgroupService: WorkgroupService
  ) {
    // console.debug('Event..ContentComponent(DEBUG) CONSTRUCTOR initialize.................' + new Date().toLocaleString());
  }

  ngOnInit() {
    this.student = this._persistenceSvc.read(aagSessionStorageConstants.SESSIONSTUDENT);
    if (!this.student) {
      // console.debug('no student');
      this._router.navigateByUrl('/');
      return;
    }

    this._activatedRoute.data.subscribe((data) => {
      this._routerData = data;

      if (this.headline() == 'Travel info') {
        this.showTravel = true;
      }

      this.userContent = this.content(this.headline());

      let positionId = this.workgroupService.getCurrentJobPositionId();

      if (this.tileCategory() && positionId) {
        // console.log('Call Tileservice: ' + this.tileCategory());
        this._tileService
          .fetch(this.tileCategory(), this.student.trainingClasses[0].program.id, positionId)
          .subscribe((tileFetchResponse) => {
            switch (tileFetchResponse.status) {
              case ServiceStatus.SUCCESS:
                this.tiles = tileFetchResponse.tiles;
                break;
              case ServiceStatus.DOES_NOT_EXIST:
                this.tileServiceErrorMsg = 'There was a problem fetching content.';
                break;
              case ServiceStatus.SYSTEM_FAILURE:
                this.tileServiceErrorMsg = 'There was a problem fetching content.';
                break;
              case ServiceStatus.TIMEOUT:
                this.tileServiceErrorMsg = 'There was a problem fetching content.';
                break;
            }
          });
      }
    });
  }

  content(title: string): string {
    // console.log('RouteData: ' + this._routerData);
    switch (title) {
      case 'Welcome aboard':
        return this.contentWelcomeaboard();
      case 'What to pack':
        return this.contentWhattopack();
      case 'What to wear':
        return this.contentWhattowear();
      case 'What to expect':
        return this.contentWhattoexpect();
      default:
        return '';
    }
  }

  contentWelcomeaboard(): string {
    return `<h2>Welcome aboard</h2>
    <p>
      Congratulations on your new role. We are excited to have you join
      the team.
    </p>
    <p>
      We chose you to become part of our team, because we&rsquo;re
      confident that you will continue our decades-old legacy of providing
      the highest level of service to our guests. You play a vital role so
      we will do all we can to ensure you have the resources and support
      needed to be successful.
    </p>
    <p>
      As you jump into your training and onboarding experience, take some
      time to look around at the resources available on this website.
      You'll find the info here you need to be prepped and ready to go
      when you arrive for training.
    </p>
    `;
  }

  contentWhattopack(): string {
    return `<h2>What to pack</h2>
    <h3>It's important to look your best and that begins with bringing the right suitcase.</h3>
    <p>If you're travelling for business we'll cover your first two checked bags, but it's
    your job to keep them within the size limit and underweight. Before you get packing,
    take a moment to familiarize yourself with our
    <a href="https://www.alaskaair.com/content/travel-info/baggage/overview" target="_blank">baggage polices</a>.</p>
    <p>When you're ready to start, here's some stuff you also want:</p>
    <ul>
    <li>Comfortable clothes for relaxing at the hotel or exploring the city</li>
    <li>Toiletries and anything you need to stay fresh</li>
    <li>Study tools: pen, paper and blank notecards</li>
    <li>Travel sewing kit</li>
    <li>Headphones to use in the classroom</li>
    <li>Cell phone charger</li>
    <li>Pictures of your loved ones &mdash; <em>either two or four legged</em></li>
    </ul>
    <p><em>Note that if you already have a uniform that you wear for work at
    Alaska or Horizon, feel free to sport it in class.</em></p>`;
  }

  contentWhattowear(): string {
    return `<h2>What to wear</h2>
    <h3>Represent our brand well and look the part during classroom training</h3>
    <p>In short, appearance standards for training are business casual, but that's
    probably not everything you brought with you. It's not appropriate to wear what
    you think might look snappy at the club, pool or gym to class. We ask employees
    to dress appropriately for the role and gender they identify with.</p>`;
  }

  contentWhattoexpect(): string {
    let programDescription = this.student?.trainingClasses[0]?.program?.description;

    if (programDescription) {
      //Remove html tags that cause formatting issues.  These tags originate from the sharepoint site
      //Remove style tag
      const regexStylePattern = /(style=".+?")/gm;
      programDescription = programDescription.replace(regexStylePattern, '');

      //Remove paragraph tags
      programDescription = programDescription.replace(/<p[^>]*>/g, '<span>').replace(/<\/p>/g, '</span>');

      // console.log('programDescription(PRE): ' + this.student?.trainingClasses[0]?.program?.description);
      // console.log('programDescription(POST): ' + programDescription);
    } else {
      programDescription = 'Nothing specified';
    }

    return (
      `<h2>What to expect</h2>
    <h3>Everything you need to know</h3>
    <h4>` +
      programDescription +
      `</h4>`
    );
  }

  headline() {
    if (this._routerData.title) {
      return this._routerData.title;
    }
    return '';
  }

  shouldShowHotel() {
    if (this._routerData.title == 'Travel info') {
      return true;
    }
    return false;
  }

  tileCategory() {
    if (this._routerData.title == 'What to wear') {
      return 'whattowear';
    } else if (this._routerData.title == 'What to expect') {
      return 'whattoexpect';
    }

    return null;
  }

  // get tiles(): Array<Tile> {
  //   return this._tiles;
  // }

  signOut() {
    this._persistenceSvc.write(aagSessionStorageConstants.SESSSIONRESET, 'NOW');
    this._router.navigateByUrl('/');
  }

  daysUntilClass() {
    var today = new Date();
    var classDate = new Date(this.student.trainingClasses[0].startDate);
    return Math.floor((today.valueOf() - classDate.valueOf()) / 86400000) * -1;
  }
}
