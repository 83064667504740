import { Injectable, OnDestroy } from '@angular/core';
import {
  OidcSecurityService,
  PublicEventsService,
  EventTypes,
  PublicConfiguration,
  OidcClientNotification,
  AuthOptions,
} from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Logger } from '@nsalaun/ng-logger';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { aagSessionStorageConstants } from '../constants/ui-constants';
import { aagPersistentStorage } from './sessionmanager.service';

//const authCallbackRoute = `/${environment.authCallbackRoute}`;
//const logRenewEnabled = false;
//const originalRequestPathKey = 'originalRequestPath';

export interface AuthServiceAPI {
  initialize(): void;
  //moduleSetup(): void;
  // moduleDestroy(): void;
  logoff(): void;
  getIsAuthorized(): Observable<boolean>;
  authorize(): void;
  getAccessToken(): any;
  getIdToken(): any;
  // authorizedCallback(): void;
  // silentRenewCallback(hash: any): void;
  getLoggedInUser(): User;
  rememberLoggedInUser(user: User): void;
  forgetLoggedInUser(): void;
  hasLoggedIn(): boolean;
}

//https://blog.bitsrc.io/6-ways-to-unsubscribe-from-observables-in-angular-ab912819a78f
// Now, if we subscribe to a stream the stream will be left open and the callback
// will be called when values are emitted into it anywhere in the app until they
// are closed by calling the unsubscribe method.

// /**
//  * The AuthService has 2 purposes:
//  * 1) Wrap calls to the angular-auth-oidc-client so that our code does not hit the angular-auth-oidc-client objects
//  *    directly. That way, it's easy to replace the AuthService with a mock.
//  * 2) Provide a place to override/extend functionality from the angular-auth-oidc-client module to suit this application's purposes.
//  */
@Injectable()
export class AuthService implements AuthServiceAPI, OnDestroy {
  //An Subject observable state will published values that were emitted after the subscription.   No past history is provided
  public isAuthenticated$: Subject<boolean> = new Subject<boolean>();
  public isAuthenticated: boolean = false;
  public isAuthenticatedCheckAuth: boolean = false;
  configuration: PublicConfiguration;
  userDataChanged$: Observable<OidcClientNotification<any>>;
  userData$: Observable<any>;

  /**
   * Flag indicating if the AuthService has already been initialized.
   */
  private initialized = false;

  // /**
  //  * Flag that indicates if the user has already been authenticated once so that the application has started to
  //  * silently renew the id token.
  //  */
  // private isSilentRenew = false;

  // /**
  //  * Start of the URL where the application is running.
  //  * @type {string}
  //  */
  // private appUrlPrefix = `${window.location.protocol}//${window.location.hostname}:${environment.port}`;

  // /**
  //  * URL that the identity provider should redirect back to after authenticating the user. This route will be handled
  //  * by Angular.
  //  * @type {string}
  //  */
  // private authUrl = `${this.appUrlPrefix}/${environment.authCallbackRoute}`;

  // /**
  //  * URL that the identity provider should redirect back to after refreshing the id token for an already authenticated
  //  * user. This route will be handled by a static HTML file that runs in an iframe. It will NOT be handled by Angular.
  //  * @type {string}
  //  */
  // private silentRenewUrl = `${this.appUrlPrefix}/${environment.silentRenewAuthCallbackRoute}`;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private logger: Logger,
    protected router: Router,
    private persistenceSvc: aagPersistentStorage,
    private eventService: PublicEventsService
  ) {
    //DEBUG LOGGING START ONLY
    // console.log('...... DEBUG LOG START');
    // console.log('zaagAuthService(DEBUG)..console');
    // this.logger.log('zaagAuthService(DEBUG)..log');
    // this.logger.debug('zaagAuthService(DEBUG)..debug');
    // this.logger.info('zaagAuthService(DEBUG)..info');
    // this.logger.warn('zaagAuthService(DEBUG)..warn');
    // this.logger.error('zaagAuthService(DEBUG)..error');
    // console.log('...... DEBUG LOG FINISH ');
    //DEBUG LOGGING FINISH ONLY

    this.logger.debug('zaagAuthService LOGGER constructor initialize');

    // this.eventService
    //   .registerForEvents()
    //   .pipe(filter((notification) => notification.type === EventTypes.UserDataChanged))
    //   .subscribe((value) => {
    //     this.logger.debug('zaagAuthService constructor subscribed to PublicEventsService - UserDataChanged ' + JSON.stringify(value));
    //     if (value && value.value) {
    //       this.userName = value.value.name;
    //       this.psId = value.value.emplid;
    //     }
    //   });

    // this.eventService.registerForEvents().subscribe((notification) => {
    //   this.logger.debug(
    //     'zaagAuthService constructor PublicEventsService(' + this.getISOCurrentdate() + '):' + JSON.stringify(notification)
    //   );
    // });

    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.NewAuthorizationResult))
      .subscribe((value) => {
        this.logger.debug(
          'zaagAuthService constructor subscribed to PublicEventsService - NewAuthorizationResult ' + JSON.stringify(value)
        );
        if (value && value.value) {
          //Authorized / NotAuthorized
          if (value.value.authorizationState == 'Authorized') {
            this.isAuthenticated = true;
            this.isAuthenticated$.next(this.isAuthenticated);
            this.logger.debug(
              'zaagAuthServiceV2.................... ngOnInit auth(' + this.getISOCurrentdate() + '):' + this.isAuthenticated
            );
          }
        }
      });

    this.configuration = this.oidcSecurityService.configuration;
    this.userData$ = this.oidcSecurityService.userData$;

    this.logger.debug('zaagAuthService constructor finished');
  }

  /**
   * Configures angular-auth-oidc-client module and sets up overrides of some of its functionality.
   */
  initialize(): void {
    // console.debug(`zaagAuthService initialized from path ${window.location.pathname}`);

    if (this.initialized) {
      this.logger.info(`zaagAuthService Ignoring additional AuthService initialize() from path ${window.location.pathname}`);
      return;
    }

    this.logger.debug(`zaagAuthService Enable additional AuthService initialize() from path ${window.location.pathname}`);

    this.initialized = true;

    // Make it so that the silent renew iframe can get a reference to AuthService so that it can call authorizedCallback.
    // this.logger.debug('Setting global reference to window.authService');
    // (<any>window).authService = this;

    // const checkForExpiredTokenInterval = 9000; // the amount of time in milliseconds between polling to check token expiration
    // const authService = this; // reference to the AuthService so that overridden angular-auth-oidc-client objects can call it

    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      this.logger.debug('zaagAuthServiceV1..................... ngOnInit auth:' + auth);
      this.isAuthenticatedCheckAuth = auth;
    });
  }

  logoff(): void {
    this.forgetLoggedInUser();

    this.isAuthenticated = false;
    //this.isAuthenticated$.next(this.isAuthenticated);

    // if (this.silentRenewSubscription) {
    //   this.silentRenewSubscription.unsubscribe();
    //   this.silentRenewSubscription = null;
    // }
    // if (this.moduleSetupSubscription) {
    //   this.logger.debug('calling moduleSetupSubscription.unsubscribe()');
    //   this.moduleSetupSubscription.unsubscribe();
    //   this.moduleSetupSubscription = null;
    // }
    this.oidcSecurityService.logoff();
  }

  // getIsAuthorized(): Observable<boolean> {
  //  //return this.oidcSecurityService.getIsAuthorized();
  //  return of(this.isAuthenticated);
  // }

  getIsAuthorized(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  getLocalCurrentDate(): string {
    // The standard is called ISO-8601 and the format is: YYYY-MM-DDTHH:mm:ss.sssZ
    return new Date().toLocaleString();
  }

  getISOCurrentdate(): string {
    // The toISOString() method returns a date object as a string, using the ISO standard.
    return new Date().toISOString();
  }

  /**
   * This method will cause the browser to leave this web application so that the user will be taken
   * to the identity provider website for authentication.
   */
  authorize(): void {
    this.logger.debug('zaagAuthService On authorize, the requested route pathname: ' + window.location.pathname);

    if (!this.isAuthenticated) {
      this.logger.warn('zaagAuthService authorized Not authenticated.  attempting to authorize');
      this.oidcSecurityService.authorize();
    } else {
      this.logger.debug('zaagAuthService authorized authenticated');
    }
  }

  getAccessToken(): any {
    try {
      return this.oidcSecurityService.getToken();
    } catch (err) {
      this.logger.error('zaagAuthService Failed to retrieve Access token');
      // We return a value here so that a request will still be sent even if the token is corrupt. The back end service will
      // reject this corrupt token, which will force this application to reauthenticate the user with SSO
      return 'Access token could not be retrieved';
    }
  }

  getIdToken(): any {
    try {
      return this.oidcSecurityService.getIdToken();
    } catch (err) {
      this.logger.error('zaagAuthService Failed to retrieve ID token');
      // We return a value here so that a request will still be sent even if the token is corrupt. The back end service will
      // reject this corrupt token, which will force this application to reauthenticate the user with SSO
      return 'ID token could not be retrieved';
    }
  }

  getLoggedInUser(): User {
    const result = this.persistenceSvc.read(aagSessionStorageConstants.SESSIONLOGGEDINKEY);
    if (!result) {
      return null;
    }
    return result;
  }

  rememberLoggedInUser(user: User): void {
    this.persistenceSvc.write(aagSessionStorageConstants.SESSIONLOGGEDINKEY, user);
  }

  forgetLoggedInUser(): void {
    this.logger.debug('zaagAuthService Deleting logged in flag');
    this.persistenceSvc.remove(aagSessionStorageConstants.SESSIONLOGGEDINKEY);
    // if (this.hasLoggedIn()) {
    //   this.logger.error('zaagAuthService logout not successful, retrying');
    //   this.persistenceSvc.remove(aagSessionStorageConstants.SESSIONLOGGEDINKEY);
    // }
  }

  hasLoggedIn(): boolean {
    return this.persistenceSvc.check(aagSessionStorageConstants.SESSIONLOGGEDINKEY);
  }

  ngOnDestroy(): void {
    console.log('zaagAuthService ngOnDestroy zzzzz');
    //this.authService.moduleDestroy();
  }
}
