import { Component, OnInit } from '@angular/core';
import { aagPersistentStorage } from '../../services/sessionmanager.service';
import { Router } from '@angular/router';
import { Student } from '../../model/student';
import { aagApplicationConstants, aagSessionStorageConstants } from '../../constants/ui-constants';
import { WorkgroupService } from '../../services/workgroup.service';

@Component({
  selector: 'app-flight-details',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight-details.component.scss'],
})
export class FlightDetailsComponent implements OnInit {
  student: Student;
  public aagSiteContactEmail: string;
  public isId90TravelAvailable: boolean = false;
  public isConfirmationCodeAvailable: boolean = false;

  constructor(private persistenceSvc: aagPersistentStorage, private router: Router, private workgroupService: WorkgroupService) {
    // console.debug('Event..FlightDetailsComponent(DEBUG) CONSTRUCTOR initialize.................' + new Date().toLocaleString());
  }

  ngOnInit() {
    this.student = this.persistenceSvc.read(aagSessionStorageConstants.SESSIONSTUDENT);
    if (!this.student) {
      this.router.navigateByUrl('/');
      return;
    }

    this.aagSiteContactEmail = this.workgroupService.getCurrentBusinessSupportContact();

    //Depending upon the workgroup flights can be booked on ID90 or FLY
    if (this.workgroupService.getCurrentJobPositionTitle() === aagApplicationConstants.WORKGROUP_INFLIGHT) {
      this.isId90TravelAvailable = true;
    } else {
      if (this.student?.trips[0]?.confirmationCode) {
        this.isConfirmationCodeAvailable = true;
      }
    }

    // console.log('isId90TravelAvailable: ' + this.isId90TravelAvailable);
    // console.log('contact email(zzzzzzzz): ' + this.aagSiteContactEmail);
  }

  signOut() {
    this.persistenceSvc.write(aagSessionStorageConstants.SESSSIONRESET, 'NOW');
    this.router.navigateByUrl('/');
  }
}
