export const aagApplicationConstants = {
  WORKGROUP_EMAIL_CSA: 'training.coordinator@alaskaair.com',
  WORKGROUP_EMAIL_INFLIGHT: 'FA.InitialTraining@alaskaair.com',
  WORKGROUP_CSA: 'Airport Services',
  WORKGROUP_INFLIGHT: 'Inflight (Flight Attendant)',
};

export const aagSessionStorageConstants = {
  SSOISAUTHORIZED: 'appIsAuthorized',
  SSOFIRSTNAME: 'appSSOFirstName',
  SSOAUTHORIZATIONDENIED: 'appAuthorizationAccessDenied',
  SESSIONSTUDENT: 'appStudent',
  SESSIONJOBPOSITIONID: 'PERSISTJobPositionId',
  SESSIONLOGGEDINKEY: 'appAdUser',
  SESSSIONRESET: 'appReset',
};

// export const aagMessageConstants = {
//   RosterStateSuccess: 'Roster State Updated.',
// };
// export const appConstants = {
//   START_CLASS: 'START CLASS',
// };
// export const displayConstants = {
//   TRAINEETYPETEXT_MGMT: 'MGMT',
// }
// export const pageConstants = {
//   ATTENDANCE: 'Attendance',
//   DRILLS: 'Drills',
//   CLASSES: 'Classes',
// };
// export const pageRouteConstants = {
//   ROUTE_BVT: '/training/ui-bvt',
//   ROUTE_CLASSES: '/training/classes',
//   ROUTE_ROSTER: '/training/class-roster',
//   ROUTE_DRILL: '/training/drills',
// };
