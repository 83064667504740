import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { aagPersistentStorage } from '../../services/sessionmanager.service';
import { StudentService } from '../../services/student.service';
import { ServiceStatus } from '../../model/service.status';
import { SigninStatus } from '../../model/signin.status';
import { AuthService } from '../../services/auth.service';
import { Logger } from '@nsalaun/ng-logger';
import { aagSessionStorageConstants } from '../../constants/ui-constants';
import { SignInProfileService } from '../../services/signinprofile.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auth-loading',
  templateUrl: './auth-loading.component.html',
  styleUrls: ['./auth-loading.component.scss'],
})
export class AuthLoadingComponent implements OnInit, OnDestroy {
  // aagSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private signInProfileService: SignInProfileService,
    private router: Router,
    private logger: Logger
  ) {
    logger.debug('AuthLOADINGComponent(DEBUG) CONSTRUCTOR initialize.................' + new Date().toLocaleString());
  }

  ngOnInit() {
    this.logger.debug('AuthLOADINGComponent(DEBUG) INIT initialize.................' + new Date().toLocaleString());

    if (this.signInProfileService.getSSOIsAuthorized()) {
      // this.logIn(this.authService.getAccessToken());

      //Take operator makes a subscription happen once and exit
      let subscription1$ = this.signInProfileService.signinUserState$.pipe(take(1)).subscribe((signinProfileState) => {
        this.logger.debug('AuthLOADINGComponent(DEBUG) logIn subscribe.................' + new Date().toLocaleString());
        switch (signinProfileState) {
          case SigninStatus.AUTHORIZED:
            this.router.navigateByUrl('/landing');
            break;
          case SigninStatus.NOTAUTHORIZED:
            this.router.navigateByUrl('/');
            break;
          default:
            this.router.navigateByUrl('/');
            break;
        }
      });

      // this.aagSubscription.add(subscription1$);

      this.signInProfileService.SSOlogIn(this.authService.getAccessToken());
    } else {
      // this.resetUser();
      this.signInProfileService.resetSessionState();
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy() {
    this.logger.debug('AuthLoadingComponent Destroy');
    // this.aagSubscription.unsubscribe();
  }
}
