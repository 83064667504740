import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Tile, TileFetchResponse } from '../model/tile';
import { ServiceStatus } from '../model/service.status';
// import { timeout } from 'q';
import { catchError, map, timeoutWith } from 'rxjs/operators';

@Injectable()
export class TileService {
  private _tiles: Array<Tile>;

  constructor(private _http: HttpClient) {}

  fetch(tileCategory: string, programId: string, workgroup: string): Observable<TileFetchResponse> {
    // const options = {};

    const options = {
      params: new HttpParams().set('programId', programId).set('querySiteId', workgroup),
    };

    let url = '/api/1/tiles/' + tileCategory; // + '?programId=' + programId;

    return this._http.get<Tile[]>(url, options).pipe(
      timeoutWith(
        15000,
        defer(() => throwError(new HttpErrorResponse({})))
      ),
      map((response) => {
        this._tiles = response;

        return {
          status: ServiceStatus.SUCCESS,
          tiles: response,
        };
      }),
      catchError((err: HttpErrorResponse) => {
        // if (timeout === err.statusText) {
        //   return of({
        //     status: ServiceStatus.TIMEOUT,
        //     tiles: null
        //   });
        // }

        this.errorHandler(err, url, 'GET TileFetchResponse');

        // if (404 === err.status) {
        //   return of({
        //     status: ServiceStatus.DOES_NOT_EXIST,
        //     tiles: null
        //   });
        // }
        // return of({
        //   status: ServiceStatus.SYSTEM_FAILURE,
        //   tiles: null
        // });

        //console.error(err);
        //return throwError(err);
        return of({
          status: ServiceStatus.SYSTEM_FAILURE,
          tiles: null,
        });
      })
    );
  }

  /*
   * This is a common error handler to log in app insights
   * operation example format: GET Valid Bases
   * body: send it for POST, PUT, DELETE Operations
   */
  private errorHandler<T>(err: any, url?: string, operation = 'operation', reqBody?: any) {
    let errorMessage = JSON.stringify(err.error);
    let loggableErr = {} as Error;
    loggableErr.stack = errorMessage;
    //trap body as json string to add to trace in app insights
    let traceMessage: any = `${operation}-${url}`;
    if (reqBody != null) {
      traceMessage = `${operation}-${url}-${JSON.stringify(reqBody)}`;
    }

    //log trace in app insights
    //this.logService.logTrace(traceMessage, { url: url });
    console.error(traceMessage);
    //log error in app insights
    //this.logService.logException(loggableErr, 1);
    console.error(loggableErr);

    return throwError(errorMessage);
  }
}
