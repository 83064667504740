import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from '../components/welcome/welcome.component';
import { LandingComponent } from '../components/landing/landing.component';
import { ContentComponent } from '../components/content/content.component';
import { ClassDetailsComponent } from '../components/class-details/class-details.component';
import { HotelDetailsComponent } from '../components/hotel-details/hotel-details.component';
import { FlightDetailsComponent } from '../components/flight-details/flight-details.component';
import { AuthLandingComponent } from '../components/auth-landing/auth-landing.component';
import { AuthLoadingComponent } from '../components/auth-loading/auth-loading.component';
import { UnauthorizedComponent } from '../components/unauthorized/unauthorized.component';

const routes: Routes = [
    {
        path: '',
        component: WelcomeComponent
    },
    {
        path: 'landing',
        component: LandingComponent
    },
    {
        path: 'welcome-aboard',
        data: {
            title: 'Welcome aboard'
        },
        component: ContentComponent
    },
    {
        path: 'class-details',
        data: {
            title: 'Class details'
        },
        component: ClassDetailsComponent
    },
    {
        path: 'flight-info',
        data: {
            title: 'Flight info'
        },
        component: FlightDetailsComponent
    },
    {
        path: 'hotel-info',
        data: {
            title: 'Hotel info'
        },
        component: HotelDetailsComponent
    },
    {
        path: 'what-to-expect',
        data: {
            title: 'What to expect'
        },
        component: ContentComponent
    },
    {
        path: 'what-to-pack',
        data: {
            title: 'What to pack'
        },
        component: ContentComponent
    },
    {
        path: 'what-to-wear',
        data: {
            title: 'What to wear'
        },
        component: ContentComponent
    },
    {
      path: 'auth',
      component: AuthLandingComponent
    },
    {
      path: 'authLoading',
      component: AuthLoadingComponent
    },
    { path: 'forbidden', component: UnauthorizedComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }